<template>
        <md-snackbar
            :md-active="showSnackbar"
            :md-duration="4000"
            :md-persistent="true"
            :class="type === 'ERROR' ? 'snackbar-error' : ''"
        >
            {{ message }}
        </md-snackbar>
</template>

<script>

import ApiService from "../../service/ApiService";

export default {
    name: 'snackbar',
    data () {
        return {
            showSnackbar: false,
            type: '',
            message: '',
        }
    },
    created() {
        ApiService.on('NETWORK_ERROR', () => {
            this.type = 'ERROR';
            this.message = this.$i18n.tc('LOGIN.ERROR.NETWORK_ERROR')
            this.show();
        });
        this.$root.$on('SHOW_SNACKBAR', values => {
            this.type = values.error ? 'ERROR' : '';
            this.message = values.message ? values.message : 'GLOBAL_ERROR';
            this.show();
        });
    },
    methods: {
        show (){
            this.showSnackbar = true;
            clearTimeout(w)
            let w = setTimeout(()=>{
                this.showSnackbar = false;
                this.type = '';
                this.message = '';
                clearTimeout(w)
            },4000)
        },
    },

}
</script>
<style scoped>
.snackbar-error{
    background-color: darkred;
    color: white
}
</style>
