<template>
  <div class="page-selector" :class="{'selector_disabled':this.selectorDisabled}">
    <div class="sort_button pointer" v-on:click="openSelector()" >
      <span class="dropdown_text" v-if="!this.sortingByCompany && this.currentTab==='locks'">{{ $t('HOME.NAME') }}</span>
      <span class="dropdown_text" v-if="this.sortingByCompany && this.currentTab==='locks'">{{ $t('HOME.COMPANY') }}</span>
      <span class="dropdown_text" v-if="this.currentTab==='cards'">{{ $t('HOME.TRANSPONDERS') }}</span>
      <img class="icon_dropdown" src="../../assets/arrow_down_outline.svg"/>
    </div>

    <div v-if="this.showSelector" class="sort_button_second selector">

<!--      <div class="selector_selected" v-if="!this.sortingByCompany && this.currentTab==='locks'">{{ $t('HOME.NAME') }}</div>-->
<!--      <div class="selector_selected" v-if="this.sortingByCompany && this.currentTab==='locks'">{{ $t('HOME.COMPANY') }}</div>-->
<!--      <div class="selector_selected" v-if="this.currentTab==='cards'">{{ $t('HOME.TRANSPONDERS') }}</div>-->

      <div v-if="this.sortingByCompany && this.currentTab==='locks'" class="pointer" v-on:click="emitChangeSortingMethod()">{{ $t('HOME.NAME') }}</div>
      <div v-if="!this.sortingByCompany && this.currentTab==='locks'" class="pointer" v-on:click="emitChangeSortingMethod()">{{ $t('HOME.COMPANY') }}</div>

      <div v-if="this.currentTab==='locks' && showTranspondersLink" class="selector_item pointer" v-on:click="emitTabSwitch('cards')">{{ $t('HOME.TRANSPONDERS') }}</div>

      <div v-if="this.currentTab==='cards'" class="pointer" v-on:click="emitChangeTabAndSorting(false)">{{ $t('HOME.NAME') }}</div>
      <div v-if="this.currentTab==='cards'" class="selector_item pointer" v-on:click="emitChangeTabAndSorting(true)">{{ $t('HOME.COMPANY') }}</div>

    </div>
    <div v-if="this.showSelector === true" v-on:click="closeSelector()" class="clickableBg"></div>
  </div>
</template>

<script>
export default {
  name: 'PageSelector',
  props: ['currentTab', 'sortingByCompany', 'showTranspondersLink', 'selectorDisabled'],
  data () {
    return {
      showSelector: false,
    }
  },
  methods: {
    openSelector: function () {
      if(!this.selectorDisabled){
        this.showSelector = true
      }
    },
    closeSelector: function () {
      this.showSelector = false;
    },
    emitTabSwitch(tab){
      this.showSelector = false;
      this.$emit('tabSwitch', tab)
    },
    emitChangeSortingMethod(){
      this.showSelector = false;
      this.$emit('changeSortingMethod')
    },
    emitChangeTabAndSorting(sortByCompany){
      this.showSelector = false;
      this.$emit('changeSortingAndTabSwitch', sortByCompany)
    }
  }
}
</script>

<style scoped>
  .page-selector {
    display: inline-block;
    padding: 0 20px;
    color: #000;
  }
  .sort_button {
    margin-left: 14px;
    padding: 10px 16px;
    border: 1px solid #c2a977;
    border-radius: 6px;
    background-color: #ffffff;
    width: 160px;
    display: flex;
    justify-content: space-between;
  }
  .sort_button_second{
    margin-top: 10px;
    margin-left: 14px;
    padding: 14px 16px 10px 16px;
    border: 1px solid #c2a977;
    border-radius: 6px;
    background-color: #ffffff;
    width: 160px;
    box-shadow: 4px 4px 10px 0px lightgray;
  }
  .clickableBg {
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100vh;
  }
  .selector{
    position: absolute;
    z-index: 2;
  }
  .pointer{
    cursor: pointer;
  }
  .selector_item{
    padding-top: 16px;
    padding-bottom: 4px;
  }
  .selector_selected{
    cursor: default;
    font-weight: bold;
  }
  .selector_disabled{
    opacity: 0.3;
  }
</style>
