<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <lottie-animation
                    path="assets/spinner.json"
                    :autoPlay="true"
                    :width="256"
                    :height="256"
            />
        </div>
    </div>
</template>

<script>
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"

    export default {
        components: {
            LottieAnimation
        },
        name: 'Spinner',
    };
</script>

<style scoped>
  .value {
    position: absolute;
    y: 100px;
    top: 116px;
    left: 115px;
  }
</style>