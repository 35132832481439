<template>
    <router-view />
</template>

<script>
    const GoogleFontsPlugin = require("google-fonts-webpack-plugin")

    export default {
    name: 'App',
    plugins: [
        new GoogleFontsPlugin({
            fonts: [
                { family: "Mulish" },
            ]
        })
    ],
    components: {
    },
}
</script>

<style>
    .protocol-datepicker-content .selected{
      background-color: #c3ab79 !important;
      border-radius: 30px !important;
    }

    .picker-class {
      padding: 15px;
    }

    .picker-input-class{
      border: white;
      font-size: 16px;
      border-bottom: 1px solid black;
      padding: 7px 0;
    }

    .picker-input-class:focus{
      outline: none;
    }

    @font-face {
        font-family: "Mulish";
        src: local("Mulish"),
        url('./font/Mulish-Regular.ttf') format("truetype");
    }


    body, html {
        font-family: Mulish, sans-serif;

        height: 100%;

    }
    .error-message{
        color: #cf7266;
        font-size: 14px;
        padding-top: 10px;
        text-align: left;
        margin: auto;
        width: 400px;
    }
    #login-view {
        height: 100vh;
        background-color: #242b3b;
        min-height: 800px;
        font-family: Mulish, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #fff;
    }
    .login-data {
        float: left;
        width: 770px;
        height: calc(100vh - 100px);
    }
    .header {
        text-align: left;
    }
    .header-login {
        text-align: left;
    }
    .user-status-login {
        float: right;
        padding: 30px 60px;
        margin-right: -50px;
        position: relative;
    }
    .title {
        position: relative;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 49px;
        letter-spacing: 1.1px;
    }
    .subtitle {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 25px;
    }
    .logo {
        width: 150px;
    }
    .phone {
        width: 400px;
        margin: auto;
        background-color: #ffffff;
        border-radius: 8px;
    }
    .phone-container {
        margin-top: 48px;
    }
    .code-container {
        margin-top: 15px;
    }
    .second-block{
        margin-top: 50px;
    }
    .third-block {
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .input-label {
        font-size: 16px;
        padding-top: 33px;
    }
    .input-field {
        border: none;
        -webkit-appearance: none;
        outline: none;
        text-align: center;
        padding: 18px;
        width: 380px;
        font-size: 16px;
    }
    .continue-button {
        font-size: 16px;
        padding: 15px;
        border-radius: 30px;
        color: black;
        background-image: linear-gradient(to right, #ccba94 , #bca77a);
        width: 396px;
        margin: auto;
        cursor: pointer;
    }
    .disabled-button {
        font-size: 16px;
        padding: 15px;
        border-radius: 30px;
        color: #666666;
        background-image: linear-gradient(to right, #e7dfce , #e0d7c2);
        width: 396px;
        margin: auto;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .other-info {
        font-size: 10px;
        margin-top: 10px;
        padding-bottom: 20px;
    }
    .subtitle-other-info {
        font-size: 14px;
        line-height: 27px;
    }
    .info {
        font-size: 16px;
        line-height: 25px;
        margin-top: 50px;
    }
    .logos {
        margin-top: 20px
    }
    .apple {
        height: 35px !important;
        margin: 5px;
    }
    .google {
        height: 35px !important;
        margin: 5px;
    }

    .spinner {
        border: 16px solid #bca77a;
        border-top: 16px solid #000000;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 260px;
        text-align: center;
        z-index: 100;
    }
    .back{
        position: absolute;
        left: 186px;
        cursor: pointer;
        width: 24px;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .footer {
        position: sticky;
        top: 100vh;
        height: 220px;
    }
    .modal-footer {
        text-align: right;
    }
    .modal_close {
        float: right;
        position: relative;
        right: -18px;
        top: -8px;
        margin-left: -32px;
        width: 32px;
        cursor: pointer;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 430px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        background-image: url("assets/header_background.png");
        background-repeat: repeat-x;
    }

    .modal-header {
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 40px 0;
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    :root {
        --md-theme-default-primary: #c2a977;
    }
    .md-field.md-theme-default:before {
        background-color: #c2a977;
        background-color: var(--md-theme-default-primary, #c2a977);
    }
    label {
        color: #000 !important;
    }
    ::-moz-selection{
        color: white !important;
        background: black !important;
    }
    ::selection{
        color: black !important;
        background: #e1d4bb !important;
    }
    .md-field.md-theme-default:before {
        background-color: #c2a977 !important;
    }
    .login-general-container{
        display: flex;
    }
    .side-image-container{
        background-image: url("assets/login-picture.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        flex-grow: 1;
    }
</style>
