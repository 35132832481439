<template>
    <div class="language-switch">
        <div class="language-image pointer" >
            <img v-on:click="openLanguageMenu()" src="../../assets/language.svg"/>
        </div>
        <div v-if="this.showLanguageMenu" :class="background == 'dark' ? 'languageMenuClassDark' : 'languageMenuClass'  ">
            <div class="languageMenuContent">
                <div :class="{'selectable' : this.language !== 'de'}" class="language-link" v-on:click="setLanguage('de')">Deutsch</div>
                <br>
                <div :class="{'selectable' : this.language !== 'en'}" class="language-link"  v-on:click="setLanguage('en')">English</div>
            </div>
        </div>
        <div v-if="this.showLanguageMenu === true" v-on:click="closeLanguageMenu()" class="clickableBg"></div>
    </div>
</template>
<script>
export default {
    name: 'LanguageSwitch',
    props: {
        background: String
    },
    data () {
        return {
            showLanguageMenu: false,
            language: this.currentLanguage
        }
    },
    mounted(){
        this.language = this.currentLanguage
    },
    methods: {
        openLanguageMenu: function () {
            this.showLanguageMenu = true
        },
        closeLanguageMenu: function () {
            this.showLanguageMenu = false;
        },
        setLanguage(language){
            this.language = language
            this.currentLanguage = language
            this.showLanguageMenu = false;
            this.$emit('selectPickerLanguage')
        },
    },
    computed: {
        currentLanguage: {
            get() {
                return this.$store.getters.currentLanguage;
            },
            set(newValue) {
                this.$store.dispatch('updateCurrentLanguage', newValue);
                this.$i18n.locale = newValue;
            }
        }
    },

}
</script>
<style scoped>
    .language-switch {
        display: inline;
        padding: 0 20px;
        color: #000;
    }
    .languageMenuClass{
        position: absolute;
        width: 140px;
        right: 86px;
        top: 82px;
        border-radius: 12px;
        box-shadow: 2px 2px 4px 2px #A7A9AB;
        background-color: white;
        z-index: 1;
    }
    .languageMenuClassDark{
        position: absolute;
        width: 140px;
        right: 86px;
        top: 82px;
        border-radius: 12px;
        box-shadow: 2px 2px 4px 2px #171E27;
        background-color: white;
        z-index: 1;
    }
    .languageMenuContent{
        padding: 16px;
    }
    .clickableBg {
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100vh;
    }
    .language-image {
        display: inline;
        z-index: 1;
        cursor: pointer;
    }
    .language-link{
        font-size: 16px;
        font-weight: bold;
        cursor: default;

    }
    .selectable {
        cursor: pointer;
        font-weight: normal;
    }
    .selectable:hover {
        text-decoration: underline;
    }
</style>
