<template>
    <div class="login-general-container">
        <div id="login-view">
          <Spinner v-if="this.loading" />
          <div class="header-login">
              <img alt="Vue logo" class="logo" src="../../assets/logo.png">
              <div class="user-status-login">
                  <language-switch background="dark"/>
              </div>
          </div>
          <div class="login-data">
              <div class="title">
                  {{ $t('LOGIN.TITLE') }}
              </div>
              <div class="subtitle" v-html="$t('LOGIN.SUBTITLE')" />
              <div class="subtitle-other-info" v-html="$t('LOGIN.SUBTITLE_OTHER_INFO')" />
              <div class="phone-container">
                  <div class="phone">
                      <input v-on:focus="focused" :change="validatePhonenumber(phoneNumber)" class="input-field" v-model="phoneNumber"/>
                  </div>
              </div>
              <div class="second-block">
                  <div :class="{'continue-button':(this.phoneNumberIsValid && !this.loading), 'disabled-button':!this.phoneNumberIsValid || this.loading}  " @click.prevent="loginUser">{{ this.$i18n.t('LOGIN.CONTINUE') }}</div>
              </div>

              <div class="footer">
                  <div class="info" v-html="$t('LOGIN.FOOTER_INFO')" />
                  <div class="logos">
                      <a href="https://apps.apple.com/de/app/akii/id1495186422">
                        <img alt="apple logo" class="apple" src="../../assets/appStore.png">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.zeppelin.zlab.akii">
                        <img alt="google logo" class="google" src="../../assets/googlePlay.png">
                    </a>
                  </div>
                  <div class="other-info" v-html="$t('LOGIN.FOOTER_OTHER_INFO')" />
              </div>
          </div>
        </div>
        <div class="side-image-container"></div>
        <snackbar />
    </div>
</template>

<script>
import SessionService from '../../service/SessionService';
import ApiService from "../../service/ApiService";
import Spinner from "../element/Spinner"
import Snackbar from "../element/Snackbar";
import LanguageSwitch from "../element/LanguageSwitch";
import GoogleTagManagerService from "@/service/GoogleTagManagerService";

export default {
    components: {
        LanguageSwitch,
        Snackbar,
        Spinner
    },
    data () {
        return {
            snackbarErrorMessage: '',
            loading: false,
            phoneNumber: '+49',
            phoneNumberIsValid: false,
            phoneValidationRegex: /^\+(?:[0-9] ?){6,14}[0-9]$/
        }
    },
    name: 'Login',
    props: {
        msg: String,
    },
    mounted() {
        if (SessionService.isSessionStarted()) {
            this.$router.push({ name: 'home' });
        }
    },
    methods: {
        focused(){
            let tempNum = JSON.parse(JSON.stringify(this.phoneNumber));
            this.phoneNumber = ''
            setTimeout(()=>{
                this.phoneNumber = tempNum
            }, 10);
        },
        validatePhonenumber(inputtxt) {
            if (inputtxt.match(this.phoneValidationRegex)) {
              this.phoneNumberIsValid = true
            } else {
                this.phoneNumberIsValid = false
            }

        },
        loginUser(){
            if (!this.phoneNumberIsValid || this.loading) {
                return
            }
            this.loading = true;
            let redirectToTokenPage = true;
            ApiService.getLoginToken(this.phoneNumber).then(() => {
                GoogleTagManagerService.pushToDataLayer({event: 'login-success'});
            }).catch((err) => {
                GoogleTagManagerService.pushToDataLayer({event: 'login-failed'});
                console.log(err)
                redirectToTokenPage = false;
            }).finally(() => {
                this.loading = false;
                if (redirectToTokenPage) {
                    this.$router.push({
                        name: 'verify-token',
                        params: { phoneNumber: this.phoneNumber }
                    });
                }
            })
        },
    }
}
</script>

