<template>
  <div class="page-selector" :class="{'selector_disabled':this.selectorDisabled}">
    <div class="sort_button pointer" v-on:click="openSelector()" >
      <div class="dropdown_text group-name">{{ groupName() }}</div>
      <img class="icon_dropdown" src="../../assets/arrow_down_outline.svg"/>
    </div>

    <div v-if="this.showSelector" class="sort_button_second selector">
      <div class="group-name-tree-first pointer"
           v-bind:class="{ item_selected: currentLockGroup === null}"
           v-on:click="emitSelectGroup()">
        <img :src="radioButton(false)"/>  {{ $t('HOME.NOTHING') }}
      </div>
      <div v-for="group in this.filteredLockGroups()"
           class="group-name pointer"
           v-on:click="emitSelectGroup(group)" :key="group.id" >
        <div class="group-name-tree"
             v-bind:class="{ sub_group_drop_class: group.parent_id !== null, item_selected: (currentLockGroup && currentLockGroup.id === group.id)}">
          <img :src="radioButton(group.id)"/>{{group.name}}
        </div>
      </div>

    </div>
    <div v-if="this.showSelector === true" v-on:click="closeSelector()" class="clickableBg"></div>
  </div>
</template>

<script>
export default {
  name: 'GroupSelector',
  props: ['selectorDisabled', 'currentLockGroup', 'locksGroups'],
  data () {
    return {
      showSelector: false
    }
  },
  methods: {
    radioButton: function (groupId){
      if(groupId){
        if (this.currentLockGroup && this.currentLockGroup.id === groupId) {
          return require("../../assets/radio_button_on.svg");
        }
        return require("../../assets/radio_button_off.svg");
      } else {
        if (this.currentLockGroup === null) {
          return require("../../assets/radio_button_on.svg");
        }
        return require("../../assets/radio_button_off.svg");
      }

    },
    openSelector: function () {
      if(!this.selectorDisabled){
        this.showSelector = true
      }
    },
    closeSelector: function () {
      this.showSelector = false;
    },
    emitSelectGroup(group){
      this.showSelector = false;
      this.$emit('selectGroup', group)
    },
    groupName(){
      if(this.currentLockGroup){
        return this.currentLockGroup.name
      }
      return this.$i18n.t('HOME.NOTHING')
    },
    filteredLockGroups(){
      //todo sort the groups properly
      let groupsWithLocks = this.locksGroups.filter((e)=>{return (e.hasLocks === true)})
      let firstLevelGroups = groupsWithLocks.filter((e)=>{return (e.parent_id === null)})
      let filteredGroups = []
      firstLevelGroups.forEach((e)=>{
        filteredGroups.push(e)
        groupsWithLocks.forEach((g)=>{
          if(g.parent_id === e.id){
            filteredGroups.push(g)
          }
        })
      })
      return filteredGroups
    },
  }
}
</script>

<style scoped>
.sub_group_drop_class {
  border-left: 2px solid #c2a977;
  padding-left: 10px;
  margin-left: 15px;
}
.group-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-selector {
  display: inline-block;
  padding: 0 20px;
  color: #000;
}
.sort_button {
  margin-left: 14px;
  padding: 10px 16px;
  border: 1px solid #c2a977;
  border-radius: 6px;
  background-color: #ffffff;
  width: 250px;
  display: flex;
  justify-content: space-between;
}
.sort_button_second{
  margin-top: 10px;
  margin-left: 14px;
  padding: 14px 16px 10px 16px;
  border: 1px solid #c2a977;
  border-radius: 6px;
  background-color: #ffffff;
  width: 250px;
  box-shadow: 4px 4px 10px 0px lightgray;
}
.clickableBg {
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100vh;
}
.selector{
  position: absolute;
  z-index: 2;
  max-height: 600px;
  overflow: auto;
  padding-bottom: 10px;
}
.pointer{
  cursor: pointer;
}
.group-name-tree{
  padding-top: 16px;
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.group-name-tree-first{
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selector_selected{
  cursor: default;
  font-weight: bold;
}
.selector_disabled{
  opacity: 0.3;
}
</style>
