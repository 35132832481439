import LoginView from './components/view/Login';
import VerifyTokenView from './components/view/VerifyToken';
import HomeView from './components/view/Home';

let defaultRoutes = [
    {
        path: '/login',
        component: LoginView,
        name: 'login-view',
        meta: { requireLogin: false },
    },
    {
        path: '/verify-token',
        component: VerifyTokenView,
        name: 'verify-token',
        meta: { requireLogin: false },
        props: true,
    },
    {
        path: '/',
        component: HomeView,
        name: 'home',
        meta: { requireLogin: true },
    },
];

export default {
    routes: defaultRoutes,
    linkActiveClass: 'active',
    mode: 'history'
};
