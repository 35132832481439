'use strict';

const KEY_SESSION_TOKEN = 'akii-session-token';

export default class SessionService
{
    /**
     * @returns {string | null}
     */
    static getAccessToken()
    {
        return localStorage.getItem(KEY_SESSION_TOKEN);
    }

    /**
     * @param token string
     */
    static setAccessToken(token)
    {
        localStorage.setItem(KEY_SESSION_TOKEN, token);
    }

    /**
     * @returns bool
     */
    static isSessionStarted()
    {
        const token = localStorage.getItem(KEY_SESSION_TOKEN);
        return (token && token.length) > 0;
    }

    static deleteSession()
    {
        return localStorage.removeItem(KEY_SESSION_TOKEN);
    }

}
