export default class GoogleTagManagerService {

    static getTagManagerKey()
    {
        return 'GTM-WGLNRZV';
    }

    static pushToDataLayer()
    {
        try {
            /* global dataLayer */
            dataLayer.push.apply(null, arguments);
        } catch (err) {
            console.error(err);
        }
    }

    static sendVirtualPageView(eventName, virtualPageURL)
    {
        GoogleTagManagerService.pushToDataLayer({
            'event': eventName,
            'virtualPageURL': virtualPageURL
        });
    }
}
