<template>
    <div class="login-general-container">
        <div id="login-view">
          <Spinner v-if="this.loading" />
            <div class="header-login">
                <img alt="Vue logo" class="logo" src="../../assets/logo.png">
                <div class="user-status-login">
                    <language-switch background="dark"/>
                </div>
            </div>
          <div class="login-data">
              <div class="title">
                  <img alt="back" @click.prevent="goBack" class="back" src="../../assets/back.svg">
                  {{ $t('VERIFY_TOKEN.TITLE') }}
              </div>
              <div class="subtitle" v-html="$t('VERIFY_TOKEN.SUBTITLE', {phoneNumber: phoneNumber})" />
              <div class="input-label">{{ $t('VERIFY_TOKEN.CODE') }}</div>
              <div class="code-container">
                  <div class="phone">
                      <input class="input-field" v-model="token"/>
                  </div>
              </div>
              <div v-if="this.errorMessage" class="error-message">{{ $t('VERIFY_TOKEN.ERROR.WRONG_CODE') }}</div>

              <div class="subtitle third-block" v-html="$t('VERIFY_TOKEN.SUBTITLE_THIRD_BLOCK')" />

              <div :class="{'continue-button':(this.token.length > 5 && this.loading === false), 'disabled-button':this.token.length <6 || this.loading === true}  " @click.prevent="verifyToken">{{ this.$i18n.tc('LOGIN.LOGIN') }}</div>
          </div>
        </div>
        <div class="side-image-container"></div>
        <snackbar />
    </div>
</template>

<script>
  import SessionService from '../../service/SessionService';
  import ApiService from "../../service/ApiService";
  import Spinner from "../element/Spinner"
  import LanguageSwitch from "../element/LanguageSwitch";
  import Snackbar from "../element/Snackbar";

  export default {
      components: {
          LanguageSwitch,
          Snackbar,
          Spinner
      },
    data () {
      return {
          errorMessage: false,
          loading: false,
          token: ''
      }
    },
    name: 'VerifyToken',
    props: {
      msg: String,
      phoneNumber: {
        type: String,
        default: null,
      }
    },
    mounted() {
      if (SessionService.isSessionStarted()) {
        this.$router.push({ name: 'home' });
      }
    },
    methods: {
        goBack(){
            console.log("go back")
            this.$router.push({ name: 'login-view' }).catch(()=>{});
        },
        verifyToken(){
            if (this.token.length < 6) {
                return
            }
            this.loading = true;

            ApiService.verifyLoginToken(this.phoneNumber, this.token).then(result => {
                SessionService.setAccessToken(result.data.access_token);
                this.$router.push({ name: 'home' });
            }).catch(error => {
                console.log("ERROR", error);
                if (!error.response) {
                    ApiService.emit('NETWORK_ERROR');
                } else {
                    this.errorMessage = true;
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    }
  }
</script>
